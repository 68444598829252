import React from "react";
import "./../App.css";
import { ListGroup, ListGroupItem, ListGroupItemHeading } from "shards-react";
import Collapsible from "react-collapsible";

import {
  dispatchEvent,
  TORUS_INITIALIZED,
  USER_DENIED_SIGNATURE,
  USER_SIGNATURE_PROVIDED_ON_BOOK_RATE,
  LOGOUT,
  BICONOMY_LOGIN,
  USER_SIGNATURE_PROVIDED_ON_ADD_TO_FAV,
  BOOK_ADDED_TO_FAV
} from "./../util";
let data = require("./../books.json");

class MyFavList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      open: false
    };
    this.getUserBookList = props.getUserBookList;
    this.loadUserBookList = this.loadUserBookList.bind(this);
    this.triggerOpen = this.triggerOpen.bind(this);
  }

  componentDidMount() {
    let self = this;
    document.addEventListener(BICONOMY_LOGIN, async function(event) {
      self.loadUserBookList();
    });

    document.addEventListener(LOGOUT, async function(event) {
      self.setState({ list: [] });
    });

    document.addEventListener(BOOK_ADDED_TO_FAV, async function(event) {
      if (event.detail && event.detail.bookId) {
        let list = self.state.list;
        list.push(event.detail.bookId);
        self.setState({ list: list });
      }
    });
  }

  async loadUserBookList() {
    let response = await this.getUserBookList();
    let bookList = [];
    if (response) {
      for (let i = 0; i < response.length; i++) {
        let bookResult = response[i];
        bookList.push(parseInt(bookResult));
      }
      this.setState({ list: bookList });
    }
  }

  triggerOpen() {
    let state = this.state.open;
    this.setState({ open: !state });
  }

  render() {
    let heading = (
      <div className="bookshelf-header">
        My Bookshelf
        {!this.state.open && (
          <i className="material-icons bookshelf-header-icon">
            arrow_drop_down
          </i>
        )}
        {this.state.open && (
          <i className="material-icons bookshelf-header-icon">arrow_drop_up</i>
        )}
      </div>
    );

    return (
      <Collapsible
        trigger={heading}
        open={this.state.open}
        handleTriggerClick={this.triggerOpen}
      >
        <ListGroup className="my-fav-list">
          {this.state.list.map(item => {
            return data.Books.map((data, index) => {
              if (data.BookId == item) {
                return <ListGroupItem key={data}>{data.Title}</ListGroupItem>;
              }
            });
          })}
          {!this.props.loggedIn && (
            <ListGroupItem>
              <div className="book-shelf-login-message">
                Please login to see your book shelf
              </div>
            </ListGroupItem>
          )}

          {this.props.loggedIn && this.state.list.length == 0 && (
            <ListGroupItem>
              <div className="empty-book-shelf">
                <div>Empty Shelf</div>Add some books to your list.
              </div>
            </ListGroupItem>
          )}
        </ListGroup>
      </Collapsible>
    );
  }
}

export default MyFavList;
