import React from "react";
import "./../App.css";
import { Modal, ModalHeader, ModalBody } from "shards-react";
import { Typography, Button, Paper } from "@material-ui/core";

class Congratulations extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = props.toggle;
  }

  render() {
    return (
      <div>
        <Modal
          open={this.props.open}
          toggle={this.toggle}
          backdrop={true}
          backdropClassName="congrats-back-drop">
            <ModalHeader className="congrats-header">🎉 Congratulations</ModalHeader>
            <ModalBody className="congrats-modal-body">
              <Typography>
              Your transaction was recorded on blockchain forever without spending any crypto currency.
              </Typography>
              <br/>
              <Typography>
                All this was possible using meta-transactions powered by <span className="biconomy-text">Biconomy</span>
              </Typography>
              <div className="congrats-button-container">
                <Button variant="outlined" href="https://docs.biconomy.io/how-to-integrate-mexa" target="_blank">See the Docs</Button>
                <Button variant="contained" color="primary" onClick={this.toggle}>Close</Button>
              </div>
            </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default Congratulations;
