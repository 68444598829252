import Web3 from "web3";
import Torus from "@toruslabs/torus-embed";
import { dispatchEvent, TORUS_INITIALIZED } from "./../util";

const web3Obj = {
  web3: new Web3(),
  setweb3: function(provider) {
    const web3Inst = new Web3(provider);
    web3Obj.web3 = web3Inst;
    web3Obj.provider = provider;
    localStorage.setItem("pageUsingTorus", true);
  },
  initialize: async function() {
    const torus = new Torus();
    await torus.init();
    await torus.ethereum.enable();
    web3Obj.setweb3(torus.provider);
    web3Obj.logout = torus.logout;
    console.log(web3Obj.logout);
    dispatchEvent(TORUS_INITIALIZED, "Torus initialized");
  }
};
export default web3Obj;
