import React from "react";
import { Button } from "shards-react";
import {
  NotificationManager
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import { getPortisInstance } from "./../util";
import Biconomy from '@biconomy/mexa';
import Web3 from 'web3';

const { config } = require("./../config");
const defaultStatus = "Login with Portis";
const DEMO_WITH_META_TX = "DEMO_WITH_META_TX";
export default class PortisLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      account: "",
      status: defaultStatus
    };
    this.setLogin = props.setLogin;
    this.initDapp = props.initDapp;
    this.dismissPopup = props.dismissPopup;
    this.portisLogin = props.portisLogin;
    this.setDefaulStatus = this.setDefaulStatus.bind(this);
  }

  setDefaulStatus() {
    this.setState({ status: defaultStatus });
  }

  componentDidMount() {

  }

  enablePortis = () => {
    this.setState({ status: "Logging in ..." });

    const portis = getPortisInstance(this.props.selectedNetworkId);
    if(!portis) {
      NotificationManager.error("Selected network not supported by portis", "Error", 3000);
    }
    let self = this;
    if(localStorage.displayPage == DEMO_WITH_META_TX) {
      let biconomy = new Biconomy(portis.provider, {
        apiKey: config.networkConfig[this.props.selectedNetworkId].bookReadAPIKey,
        debug: true
      });
      biconomy.onEvent(biconomy.READY, () => {
        let web3 = new Web3(biconomy);
        this.setState({ status: "Getting your account ..." });
        web3.eth.getAccounts((error, accounts) => {
          if(!error) {
            localStorage.setItem("portisAddress", accounts[0]);
            self.dismissPopup();
            self.setLogin(biconomy, undefined, portis);
          }
        });
      }).onEvent(biconomy.ERROR, (error) => {
        self.setDefaulStatus();
        if(error.code == 'B505') {
          console.error('Please change the network in portis to matic test network.');
          NotificationManager.error("Please change portis network to matic test network.", "Error", 3000);
        } else {
          NotificationManager.error(error.message, "Error", 3000);
        }
      });
    } else {
      let web3 = new Web3(portis.provider);
      web3.eth.getAccounts((error, accounts) => {
        if(!error) {
            localStorage.setItem("portisAddress", accounts[0]);
            self.dismissPopup();
            self.setLogin(undefined, undefined, portis);
        }
      });
    }
  }

  render() {
    return (
      <div className="p-3 portis-login-container">
        <Button
          onClick={this.enablePortis}
          disabled={this.state.status != defaultStatus}
        >
          {this.state.status}
        </Button>
      </div>
    );
  }
}
