import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardImg,
  CardFooter,
  Button
} from "shards-react";
import Rating from "@material-ui/lab/Rating";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FavoriteIcon from "@material-ui/icons/Favorite";
import {
  dispatchEvent,
  TORUS_INITIALIZED,
  USER_DENIED_SIGNATURE,
  USER_SIGNATURE_PROVIDED_ON_BOOK_RATE,
  LOGOUT,
  BICONOMY_LOGIN,
  USER_SIGNATURE_PROVIDED_ON_ADD_TO_FAV,
  BOOK_ADDED_TO_FAV,
  ERROR
} from "./../util";
import axios from "axios";
import BigNumber from "big-number";

import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import "react-notifications/lib/notifications.css";

const StyledRating = withStyles({
  iconFilled: {
    color: "#ff6d75"
  },
  iconHover: {
    color: "#ff3d47"
  }
})(Rating);

function getLabelText(value) {
  return `${value} Heart${value !== 1 ? "s" : ""}`;
}

class BookTile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: props.data.Title,
      id: props.data.BookId,
      image: props.data.Logo,
      author: props.data.Author,
      desc: props.data.Description,
      rating: 0,
      addedToList: false,
      avgRating: "fetching",
      requestStatus: "",
      statusMessage: ""
    };
    this.loggedIn = props.loggedIn;
    this.onRateBook = props.onRateBook;
    this.onRatingChange = this.onRatingChange.bind(this);
    this.getAvgBookRating = props.getAvgBookRating;
    this.loadAvgBookRating = this.loadAvgBookRating.bind(this);
    this.loadUserRating = this.loadUserRating.bind(this);
    this.getUserRating = props.getUserRating;
    this.onAddToFav = props.onAddToFav;
    this.onAddToFavClicked = this.onAddToFavClicked.bind(this);
    this.getUserFavStatus = props.getUserFavStatus;
  }

  onRatingChange(event, value) {
    let self = this;
    if (this.props.loggedIn) {
      if (this.props.torusLoggedIn) {
        this.setState({
          statusMessage: "Waiting for user approval",
          requestStatus: "AWAIT_USER_SIGNATURE"
        });
      } else if (this.props.biconomyWalletLoggedIn) {
        this.setState({
          statusMessage: "Sending your request",
          requestStatus: "AWAIT_USER_SIGNATURE"
        });
      } else if (this.props.portisLoggedIn) {
        this.setState({
          statusMessage: "Waiting for user approval",
          requestStatus: "AWAIT_USER_SIGNATURE"
        });
      } else if (this.props.metamaskLoggedIn) {
        this.setState({
          statusMessage: "Waiting for user approval",
          requestStatus: "AWAIT_USER_SIGNATURE"
        });
      }

      this.onRateBook(this.state.id, value, (error, result) => {
        if (!error) {
          this.setState({
            statusMessage: "Rated book successfully",
            requestStatus: "DONE"
          });
          setTimeout(() => {
            this.setState({ statusMessage: "", requestStatus: "" });
          }, 3000);

          self.loadAvgBookRating();
          self.loadUserRating();
        } else {
          this.setState({
            statusMessage: "Book Rating Failed",
            requestStatus: "DONE"
          });
          setTimeout(() => {
            this.setState({ statusMessage: "", requestStatus: "" });
          }, 3000);
        }
      });
    } else {
      NotificationManager.error("Please login to rate the book", "Error", 3000);
    }
  }

  onAddToFavClicked(event, value) {
    let self = this;
    if (this.props.loggedIn) {
      if (this.props.torusLoggedIn) {
        this.setState({
          statusMessage: "Waiting for user approval",
          requestStatus: "AWAIT_USER_SIGNATURE"
        });
      } else if (this.props.biconomyWalletLoggedIn) {
        this.setState({
          statusMessage: "Sending your request",
          requestStatus: "AWAIT_USER_SIGNATURE"
        });
      } else if (this.props.portisLoggedIn) {
        this.setState({
          statusMessage: "Waiting for user approval",
          requestStatus: "AWAIT_USER_SIGNATURE"
        });
      } else if (this.props.metamaskLoggedIn) {
        this.setState({
          statusMessage: "Waiting for user approval",
          requestStatus: "AWAIT_USER_SIGNATURE"
        });
      }
      this.onAddToFav(this.state.id, (error, result) => {
        this.setState({
          statusMessage: "Added to List",
          requestStatus: "DONE"
        });
        setTimeout(() => {
          this.setState({ statusMessage: "", requestStatus: "" });
        }, 3000);

        if (!error) {
          self.loadUserFavStatus();
          dispatchEvent(BOOK_ADDED_TO_FAV, { bookId: self.state.id });
        }
      });
    } else {
      NotificationManager.error("Please login to add to fav", "Error", 3000);
    }
  }

  componentDidMount() {
    let self = this;
    //self.loadAvgBookRating();
    document.addEventListener(BICONOMY_LOGIN, async function(event) {
      self.loadAvgBookRating();
      self.loadUserRating();
      self.loadUserFavStatus();
    });
    document.addEventListener(USER_DENIED_SIGNATURE, async function(event) {
      self.setState({ statusMessage: "", requestStatus: "" });
    });

    document.addEventListener(
      USER_SIGNATURE_PROVIDED_ON_BOOK_RATE,
      async function(event) {
        if (event.detail.bookId == self.state.id) {
          self.setState({
            statusMessage: "Processing your request",
            requestStatus: "PROCESSING"
          });
        }
      }
    );

    document.addEventListener(
      USER_SIGNATURE_PROVIDED_ON_ADD_TO_FAV,
      async function(event) {
        if (event.detail.bookId == self.state.id) {
          self.setState({
            statusMessage: "Processing your request",
            requestStatus: "PROCESSING"
          });
        }
      }
    );

    document.addEventListener(LOGOUT, async function(event) {
      self.setState({ rating: 0, addedToList: false, statusMessage: "", requestStatus: "" });
    });

    document.addEventListener(ERROR, async function(event) {
      self.setState({ statusMessage: "", requestStatus: "" });
    });

    if (this.loggedIn) {
      this.setState({ avgRating: "fetching" });
    } else {
      this.setState({ avgRating: 0 });
    }
  }

  async loadUserRating() {
    let response = await this.getUserRating(this.state.id);
    if (response) {
      let userRating = parseInt(response);
      this.setState({ rating: userRating });
    }
  }

  async loadAvgBookRating() {
    let response = await this.getAvgBookRating(this.state.id);
    if (response) {
      let totalCount = response.totalCount;
      let totalRating = response.totalRating;
      if (totalCount && totalRating) {
        totalCount = parseInt(totalCount);
        totalRating = parseInt(totalRating);
        if (totalCount > 0) {
          let avgRating = totalRating / totalCount;
          avgRating = Math.round(avgRating * 100) / 100;
          this.setState({ avgRating: avgRating });
        } else {
          this.setState({ avgRating: 0 });
        }
      }
    }
  }

  async loadUserFavStatus() {
    let response = await this.getUserFavStatus(this.state.id);
    if (response) {
      let userRating = parseInt(response);
      if (userRating == 1) {
        this.setState({ addedToList: true });
      }
    }
  }

  render() {
    return (
      <Card small className="book-tile">
        <div className="flex-center">
          <div className="book-tile-avg-rating">
            Average Rating : {this.state.avgRating}
          </div>
        </div>
        <CardImg
          className="book-tile-image"
          src={require("../logo/" + this.state.image)}
        />
        <CardBody className="book-tile-body">
          {this.state.addedToList && (
            <div>
              <i className="material-icons done-icon">done</i>Added to List
            </div>
          )}
          {!this.state.addedToList && (
            <Button
              className="book-tile-rate-button"
              onClick={this.onAddToFavClicked}
            >
              <i className="material-icons add-icon">add</i>Add to My List
            </Button>
          )}
        </CardBody>
        <CardFooter className="book-tile-footer">
          <div className="rating-label">Rate this book</div>
          <div className="rating-container">
            <StyledRating
              size="small"
              name={"rating" + this.state.id}
              value={this.state.rating}
              getLabelText={getLabelText}
              precision={1}
              icon={<FavoriteIcon fontSize="inherit" />}
              className="rating-bar"
              onChange={this.onRatingChange}
            />
          </div>
        </CardFooter>
        {this.state.statusMessage.length > 0 && (
          <div
            className={
              this.state.requestStatus == "AWAIT_USER_SIGNATURE"
                ? "book-tile-overlay-status"
                : "" + this.state.requestStatus == "PROCESSING"
                ? "book-tile-overlay-status book-tile-processing "
                : "" + this.state.requestStatus == "DONE"
                ? "book-tile-overlay-status book-tile-done "
                : ""
            }
          >
            {this.state.statusMessage}
            {this.state.requestStatus == "PROCESSING" && (
              <div className="lds-ellipsis">
                <div />
                <div />
                <div />
                <div />
              </div>
            )}
          </div>
        )}
      </Card>
    );
  }
}

export default BookTile;
