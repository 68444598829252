import React from "react";
import "./../App.css";
import { Modal, ModalBody } from "shards-react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import PortisLogin from "./PortisLogin";
import MetamaskLogin from "./MetamaskLogin";

class LoginModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open
    };
    this.toggle = props.toggleLogin;
  }

  render() {
    return (
      <div>
        <Modal
          open={this.props.open}
          toggle={this.toggle}
          backdrop={true}
          backdropClassName="login-back-drop"
        >
          <ModalBody className="sign-in-modal-body">
            <div className="powered-by mb-2">
              <span>powered by </span>
              <img
                src={require("./../images/logo.png")}
                className="biconomy-modal-logo"
              />
            </div>
            <i className="close-modal material-icons" onClick={this.toggle}>
              cancel
            </i>
            <Tabs defaultActiveKey="portis" id="loginTabContainer">
            {/*}
              <Tab eventKey="login" title="Login">
                <LoginForm
                  closePrevModal={this.toggle}
                  initDapp={this.props.initDapp}
                  dismissPopup={this.toggle}
                />
              </Tab>
              <Tab eventKey="register" title="Register">
                <RegisterForm
                  closePrevModal={this.toggle}
                  initDapp={this.props.initDapp}
                  dismissPopup={this.toggle}
                />
              </Tab>

              <Tab eventKey="torus" title="Login With Torus">
                <TorusLogin
                  closePrevModal={this.toggle}
                  initDapp={this.props.initDapp}
                  dismissPopup={this.toggle}
                  setLogin={this.props.setLogin}
                />
              </Tab>
              */}
              <Tab eventKey="metamask" title="Login with Metamask">
                <MetamaskLogin
                  closePrevModal={this.toggle}
                  initDapp={this.props.initDapp}
                  dismissPopup={this.toggle}
                  setLogin={this.props.setLogin}
                  selectedNetworkId={this.props.selectedNetworkId}
                />
              </Tab>
              <Tab eventKey="portis" title="Login With Portis">
                <PortisLogin
                  closePrevModal={this.toggle}
                  initDapp={this.props.initDapp}
                  dismissPopup={this.toggle}
                  setLogin={this.props.setLogin}
                  selectedNetworkId={this.props.selectedNetworkId}
                  portisLogin = {this.props.portisLogin}
                />
              </Tab>

            </Tabs>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default LoginModal;
