var constants = {
  maticTestnet : "MATIC_TESTNET",
  kovan : "KOVAN",
  version: "APP_VERSION"
}

let bookReadsABI = [
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "address",
        "name": "userAddress",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "address payable",
        "name": "relayerAddress",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "bytes",
        "name": "functionSignature",
        "type": "bytes"
      }
    ],
    "name": "MetaTransactionExecuted",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "value",
        "type": "uint256"
      }
    ],
    "name": "Receive",
    "type": "event"
  },
  {
    "payable": true,
    "stateMutability": "payable",
    "type": "fallback"
  },
  {
    "constant": false,
    "inputs": [
      {
        "internalType": "address",
        "name": "userAddress",
        "type": "address"
      },
      {
        "internalType": "bytes",
        "name": "functionSignature",
        "type": "bytes"
      },
      {
        "internalType": "bytes32",
        "name": "sigR",
        "type": "bytes32"
      },
      {
        "internalType": "bytes32",
        "name": "sigS",
        "type": "bytes32"
      },
      {
        "internalType": "uint8",
        "name": "sigV",
        "type": "uint8"
      }
    ],
    "name": "executeMetaTransaction",
    "outputs": [
      {
        "internalType": "bytes",
        "name": "",
        "type": "bytes"
      }
    ],
    "payable": true,
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "constant": true,
    "inputs": [
      {
        "internalType": "address",
        "name": "user",
        "type": "address"
      }
    ],
    "name": "getNonce",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "nonce",
        "type": "uint256"
      }
    ],
    "payable": false,
    "stateMutability": "view",
    "type": "function"
  },
  {
    "constant": false,
    "inputs": [
      {
        "internalType": "uint256",
        "name": "bookId",
        "type": "uint256"
      }
    ],
    "name": "addToFav",
    "outputs": [],
    "payable": false,
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "constant": false,
    "inputs": [
      {
        "internalType": "uint256",
        "name": "bookId",
        "type": "uint256"
      }
    ],
    "name": "removeFromFav",
    "outputs": [],
    "payable": false,
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "constant": true,
    "inputs": [
      {
        "internalType": "address",
        "name": "user",
        "type": "address"
      }
    ],
    "name": "getBookList",
    "outputs": [
      {
        "internalType": "uint256[]",
        "name": "",
        "type": "uint256[]"
      }
    ],
    "payable": false,
    "stateMutability": "view",
    "type": "function"
  },
  {
    "constant": true,
    "inputs": [
      {
        "internalType": "uint256",
        "name": "bookId",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "user",
        "type": "address"
      }
    ],
    "name": "getBookFavStatus",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "payable": false,
    "stateMutability": "view",
    "type": "function"
  },
  {
    "constant": false,
    "inputs": [
      {
        "internalType": "uint256",
        "name": "bookId",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "rating",
        "type": "uint256"
      }
    ],
    "name": "addRating",
    "outputs": [],
    "payable": true,
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "constant": true,
    "inputs": [
      {
        "internalType": "uint256",
        "name": "bookId",
        "type": "uint256"
      }
    ],
    "name": "getAvgBookRating",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "totalCount",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "totalRating",
        "type": "uint256"
      }
    ],
    "payable": false,
    "stateMutability": "view",
    "type": "function"
  },
  {
    "constant": true,
    "inputs": [
      {
        "internalType": "uint256",
        "name": "bookId",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "user",
        "type": "address"
      }
    ],
    "name": "getUserRating",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "rating",
        "type": "uint256"
      }
    ],
    "payable": false,
    "stateMutability": "view",
    "type": "function"
  }
];

let config = {};

if(process.env.REACT_APP_STAGE === "prod") {
  config.networkConfig = {
    80001 : {
      bookReadAPIKey: "5_hd9U6J0.493e80e7-bcb9-4fd5-a7e6-297b187290ea",
      bookReadsAddress: "0x570Dc3c7725182bd4d31e9dB3e155eEF067043f6",
      bookReadsABI:bookReadsABI
    },
    42 : {
      bookReadAPIKey: "I3-qgjqSH.c99155b9-4b70-4f55-a03a-1c9eac438e3d",
      bookReadsAddress: "0x98598F6DdadcF635435ab6EFaB5a5137e5A93E0F",
      bookReadsABI: bookReadsABI
    }
  }
} else if(process.env.REACT_APP_STAGE === "test") {
  config.networkConfig = {
    80001 : {
      bookReadAPIKey: "5_hd9U6J0.493e80e7-bcb9-4fd5-a7e6-297b187290ea",
      bookReadsAddress: "0x570Dc3c7725182bd4d31e9dB3e155eEF067043f6",
      bookReadsABI:bookReadsABI
    },
    42 : {
      bookReadAPIKey: "I3-qgjqSH.c99155b9-4b70-4f55-a03a-1c9eac438e3d",
      bookReadsAddress: "0x98598F6DdadcF635435ab6EFaB5a5137e5A93E0F",
      bookReadsABI: bookReadsABI
    }
  }
} else {
  config.networkConfig = {
    80001 : {
      bookReadAPIKey: "5_hd9U6J0.493e80e7-bcb9-4fd5-a7e6-297b187290ea",
      bookReadsAddress: "0x570Dc3c7725182bd4d31e9dB3e155eEF067043f6",
      bookReadsABI:bookReadsABI
    },
    42 : {
      bookReadAPIKey: "I3-qgjqSH.c99155b9-4b70-4f55-a03a-1c9eac438e3d",
      bookReadsAddress: "0x98598F6DdadcF635435ab6EFaB5a5137e5A93E0F",
      bookReadsABI: bookReadsABI
    }
  }
}

config.selectedNetworkKey= "BSN";
config.version = 1;



// matic : {
//   address: "0xC50e354066248AF3fD637aaF163A15fbA1462D3e",
//   abi: [{"constant":false,"inputs":[{"name":"bookId","type":"uint256"}],"name":"addToFav","outputs":[],"payable":false,"stateMutability":"nonpayable","type":"function","signature":"0x4d7c8c2c"},{"constant":false,"inputs":[{"name":"bookId","type":"uint256"}],"name":"removeFromFav","outputs":[],"payable":false,"stateMutability":"nonpayable","type":"function","signature":"0xb9e36caf"},{"constant":true,"inputs":[{"name":"user","type":"address"}],"name":"getBookList","outputs":[{"name":"","type":"uint256[]"}],"payable":false,"stateMutability":"view","type":"function","signature":"0xaf97fe68"},{"constant":true,"inputs":[{"name":"bookId","type":"uint256"},{"name":"user","type":"address"}],"name":"getBookFavStatus","outputs":[{"name":"","type":"uint256"}],"payable":false,"stateMutability":"view","type":"function","signature":"0x84d43494"},{"constant":false,"inputs":[{"name":"bookId","type":"uint256"},{"name":"rating","type":"uint256"}],"name":"addRating","outputs":[],"payable":false,"stateMutability":"nonpayable","type":"function","signature":"0x6760576a"},{"constant":true,"inputs":[{"name":"bookId","type":"uint256"}],"name":"getAvgBookRating","outputs":[{"name":"totalCount","type":"uint256"},{"name":"totalRating","type":"uint256"}],"payable":false,"stateMutability":"view","type":"function","signature":"0xe7aba9d2"},{"constant":true,"inputs":[{"name":"bookId","type":"uint256"},{"name":"user","type":"address"}],"name":"getUserRating","outputs":[{"name":"rating","type":"uint256"}],"payable":false,"stateMutability":"view","type":"function","signature":"0x260492cf"}]
// },
// ropsten: {
//   address: "0xc23e8709213565235eb8Cfc789e34175F346b8F4",
//   abi: [{"constant":false,"inputs":[{"name":"bookId","type":"uint256"}],"name":"addToFav","outputs":[],"payable":false,"stateMutability":"nonpayable","type":"function","signature":"0x4d7c8c2c"},{"constant":false,"inputs":[{"name":"bookId","type":"uint256"}],"name":"removeFromFav","outputs":[],"payable":false,"stateMutability":"nonpayable","type":"function","signature":"0xb9e36caf"},{"constant":true,"inputs":[{"name":"user","type":"address"}],"name":"getBookList","outputs":[{"name":"","type":"uint256[]"}],"payable":false,"stateMutability":"view","type":"function","signature":"0xaf97fe68"},{"constant":true,"inputs":[{"name":"bookId","type":"uint256"},{"name":"user","type":"address"}],"name":"getBookFavStatus","outputs":[{"name":"","type":"uint256"}],"payable":false,"stateMutability":"view","type":"function","signature":"0x84d43494"},{"constant":false,"inputs":[{"name":"bookId","type":"uint256"},{"name":"rating","type":"uint256"}],"name":"addRating","outputs":[],"payable":false,"stateMutability":"nonpayable","type":"function","signature":"0x6760576a"},{"constant":true,"inputs":[{"name":"bookId","type":"uint256"}],"name":"getAvgBookRating","outputs":[{"name":"totalCount","type":"uint256"},{"name":"totalRating","type":"uint256"}],"payable":false,"stateMutability":"view","type":"function","signature":"0xe7aba9d2"},{"constant":true,"inputs":[{"name":"bookId","type":"uint256"},{"name":"user","type":"address"}],"name":"getUserRating","outputs":[{"name":"rating","type":"uint256"}],"payable":false,"stateMutability":"view","type":"function","signature":"0x260492cf"}]
// }
// bookReadAPITokenRopsten: "o3XiJQ1S6.68fbfc92-8293-4fc4-ab72-6598b132d4b6",
// bookReadDappIdRopsten: "5d793ee9483a462d5a1041a9"
// bookReadDappId: "5d793eb7483a462d5a1041a8",
// bookReadAPIToken: "zAq_a7kW9.38360018-5f39-48b9-b9e1-a4989c3cdb48"

var countries = [
  { code: "+971", name: "United Arab Emirates" },
  { code: "+54", name: "Argentina" },
  { code: "+43", name: "Austria" },
  { code: "+61", name: "Australia" },
  { code: "+387", name: "Bosnia and Herzegovina" },
  { code: "+1", name: "United States" },
  { code: "+32", name: "Belgium" },
  { code: "+359", name: "Bulgaria" },
  { code: "+229", name: "Benin" },
  { code: "+591", name: "Bolivia" },
  { code: "+55", name: "Brazil" },
  { code: "+267", name: "Botswana" },
  { code: "+375", name: "Belarus" },
  { code: "+41", name: "Switzerland" },
  { code: "+56", name: "Chile" },
  { code: "+57", name: "Colombia" },
  { code: "+357", name: "Cyprus" },
  { code: "+420", name: "Czech Republic" },
  { code: "+49", name: "Germany" },
  { code: "+45", name: "Denmark" },
  { code: "+1829", name: "Dominican Republic" },
  { code: "+213", name: "Algeria" },
  { code: "+593", name: "Ecuador" },
  { code: "+372", name: "Estonia" },
  { code: "+34", name: "Spain" },
  { code: "+358", name: "Finland" },
  { code: "+33", name: "France" },
  { code: "+44", name: "United Kingdom" },
  { code: "+1473", name: "Grenada" },
  { code: "+995", name: "Georgia" },
  { code: "+233", name: "Ghana" },
  { code: "+224", name: "Guinea" },
  { code: "+30", name: "Greece" },
  { code: "+502", name: "Guatemala" },
  { code: "+852", name: "Hong Kong" },
  { code: "+385", name: "Croatia" },
  { code: "+36", name: "Hungary" },
  { code: "+62", name: "Indonesia" },
  { code: "+353", name: "Ireland" },
  { code: "+972", name: "Israel" },
  { code: "+91", name: "India" },
  { code: "+354", name: "Iceland" },
  { code: "+39", name: "Italy" },
  { code: "+1876", name: "Jamaica" },
  { code: "+81", name: "Japan" },
  { code: "+254", name: "Kenya" },
  { code: "+82", name: "Korea Republic of" },
  { code: "+1345", name: "Cayman Islands" },
  { code: "+370", name: "Lithuania" },
  { code: "+352", name: "Luxembourg" },
  { code: "+371", name: "Latvia" },
  { code: "+223", name: "Mali" },
  { code: "+853", name: "Macau" },
  { code: "+356", name: "Malta" },
  { code: "+230", name: "Mauritius" },
  { code: "+52", name: "Mexico" },
  { code: "+60", name: "Malaysia" },
  { code: "+264", name: "Namibia" },
  { code: "+234", name: "Nigeria" },
  { code: "+505", name: "Nicaragua" },
  { code: "+31", name: "Netherlands" },
  { code: "+47", name: "Norway" },
  { code: "+64", name: "New Zealand" },
  { code: "+507", name: "Panama" },
  { code: "+51", name: "Peru" },
  { code: "+63", name: "Philippines" },
  { code: "+48", name: "Poland" },
  { code: "+351", name: "Portugal" },
  { code: "+40", name: "Romania" },
  { code: "+381", name: "Serbia" },
  { code: "+249", name: "Sudan" },
  { code: "+46", name: "Sweden" },
  { code: "+65", name: "Singapore" },
  { code: "+386", name: "Slovenia" },
  { code: "+421", name: "Slovakia" },
  { code: "+503", name: "El Salvador" },
  { code: "+66", name: "Thailand" },
  { code: "+216", name: "Tunisia" },
  { code: "+1868", name: "Trinidad and Tobago" },
  { code: "+886", name: "Taiwan" },
  { code: "+255", name: "Tanzania" },
  { code: "+256", name: "Uganda" },
  { code: "+58", name: "Venezuela" },
  { code: "+84", name: "Vietnam" },
  { code: "+27", name: "South Africa" }
];

module.exports = { config, countries, constants };
