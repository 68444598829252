import React from "react";
import { Button } from "shards-react";
import {
  NotificationManager
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import { Biconomy } from '@biconomy/mexa';
import Web3 from 'web3';

const { config } = require("./../config");
const defaultStatus = "Login with Metamask";
const DEMO_WITH_META_TX = "DEMO_WITH_META_TX";
export default class MetamaskLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      account: "",
      status: defaultStatus
    };
    this.setLogin = props.setLogin;
    this.initDapp = props.initDapp;
    this.dismissPopup = props.dismissPopup;
    this.setDefaulStatus = this.setDefaulStatus.bind(this);
  }

  setDefaulStatus() {
    this.setState({ status: defaultStatus });
  }

  componentDidMount() {

  }

  enableMetamask = async () => {
    try{
      this.setState({ status: "Logging in ..." });
      if(window.ethereum) {
        await window.ethereum.enable();
        let self = this;
        if(localStorage.displayPage == DEMO_WITH_META_TX) {
          console.log(this.props.selectedNetworkId);
          console.log(config.networkConfig);
          let biconomy = new Biconomy(window.ethereum, {
            apiKey: config.networkConfig[this.props.selectedNetworkId].bookReadAPIKey
          });
          biconomy.onEvent(biconomy.READY, () => {
            let web3 = new Web3(biconomy);
            self.setState({ status: "Getting your account ..." });
            web3.eth.getAccounts((error, accounts) => {
              if(!error) {
                localStorage.setItem("metamaskAddress", accounts[0]);
                self.dismissPopup();
                self.setLogin(biconomy, undefined);
              }
            });
          }).onEvent(biconomy.ERROR, (error) => {
            self.setDefaulStatus();
            if(error.code == 'B505') {
              console.error('Please change the network in metamask to selected network in demo. For matic testnet refer to link https://docs.matic.network/newbies/conf-testnet-metamask/');
              NotificationManager.error("Please change metamask network to selected network. Check console for more info on this error.", "Error", 3000);
            }
          });
        } else {
          let web3 = new Web3(window.ethereum);
          self.setState({ status: "Getting your account ..." });
          web3.eth.getAccounts((error, accounts) => {
            if(!error) {
              localStorage.setItem("metamaskAddress", accounts[0]);
              self.dismissPopup();
              self.setLogin(undefined, undefined);
            }
          });
        }
      } else {
        this.setDefaulStatus();
        NotificationManager.error("Metamask not installed.", "Error", 5000);
      }
    } catch(error) {
      console.log(error);
    }
  }

  render() {
    return (
      <div className="p-3 metamask-login-container">
        <Button
          onClick={this.enableMetamask}
          disabled={this.state.status != defaultStatus}
        >
          {this.state.status}
        </Button>
      </div>
    );
  }
}
