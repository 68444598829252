const Portis = require('@portis/web3');
const ERROR = "BR_ERROR";
const TORUS_INITIALIZED = "BR_TORUS_INIT";
const BICONOMY_LOGIN = "BR_BICONOMY_LOGIN";
const USER_DENIED_SIGNATURE = "BR_USER_DENIED_SIGNATURE";
const USER_SIGNATURE_PROVIDED_ON_BOOK_RATE = "USER_SIGNATURE_PROVIDED_ON_BOOK_RATE";
const USER_SIGNATURE_PROVIDED_ON_ADD_TO_FAV = "USER_SIGNATURE_PROVIDED_ON_ADD_TO_FAV";
const BOOK_ADDED_TO_FAV = "BOOK_ADDED_TO_FAV";

const LOGOUT= "LOGOUT";

const dispatchErrorEvent = (message) => {
  dispatchEvent(ERROR, {message : message});
}

const dispatchEvent = (eventName, detailObject) => {
  document.dispatchEvent(new CustomEvent(eventName, {detail: detailObject}));
}

const getPortisInstance = (networkId) => {
	if(!networkId) {
		// Set matic test net as default network
		networkId = "8995";
	}
	switch(networkId) {
		case "80001":
			return new Portis('0f73c1d4-f57e-4836-9715-5ce7bfd6c158', {
			nodeUrl: 'https://rpc-mumbai.matic.today',
			chainId: 80001
			});
		case "42":
			console.log("Case 42")
			return new Portis('0f73c1d4-f57e-4836-9715-5ce7bfd6c158', 'kovan');

		default:
		return;
	}
};

module.exports = {
	dispatchErrorEvent,
	dispatchEvent,
	TORUS_INITIALIZED,
	ERROR,
	USER_DENIED_SIGNATURE,
	USER_SIGNATURE_PROVIDED_ON_BOOK_RATE,
	LOGOUT,
	BICONOMY_LOGIN,
	USER_SIGNATURE_PROVIDED_ON_ADD_TO_FAV,
	BOOK_ADDED_TO_FAV,
	getPortisInstance
}