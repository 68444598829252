import React from "react";
import "./../App.css";
import { Button, FormSelect } from "shards-react";

import { bounce, rubberBand } from "react-animations";
import Radium, { StyleRoot } from "radium";

const styles = {
  bounce: {
    animation: "x 1s",
    animationName: Radium.keyframes(bounce, "bounce")
  },
  rubberBand: {
    animation: "x 1s",
    animationName: Radium.keyframes(rubberBand, "rubberBand")
  }
};

const DEMO_WITH_META_TX = "DEMO_WITH_META_TX";
const DEMO_WITHOUT_META_TX = "DEMO_WITHOUT_META_TX";
const BICONOMY_EXPERIENCE = "Switch to Biconomy Experience";
const NON_BICONOMY_EXPERIENCE = "Switch to Experience without Biconomy";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      getStartedTitle: "Get Started",
      logoutTitle: "Log out",
      displayPage: DEMO_WITH_META_TX,
      switchButtonTitle: NON_BICONOMY_EXPERIENCE
    };
    this.logout = props.logout;
    this.onSwitchExperience = this.onSwitchExperience.bind(this);
    this.onNetworkChanged = props.onNetworkChanged;
  }

  componentWillMount() {
    if(localStorage && localStorage.displayPage && localStorage.displayTitle) {
      this.setState({displayPage: localStorage.displayPage, switchButtonTitle: localStorage.displayTitle});
    } else {
      localStorage.displayPage = DEMO_WITH_META_TX;
      localStorage.displayTitle = NON_BICONOMY_EXPERIENCE;
    }
  }

  onSwitchExperience() {
    if(this.state.displayPage == DEMO_WITHOUT_META_TX) {
      this.setState({
        displayPage: DEMO_WITH_META_TX,
        switchButtonTitle: NON_BICONOMY_EXPERIENCE
      });
      localStorage.displayPage = DEMO_WITH_META_TX;
      localStorage.displayTitle = NON_BICONOMY_EXPERIENCE;
    } else if(this.state.displayPage == DEMO_WITH_META_TX) {
      this.setState({
        displayPage: DEMO_WITHOUT_META_TX,
        switchButtonTitle: BICONOMY_EXPERIENCE
      });
      localStorage.displayPage = DEMO_WITHOUT_META_TX;
      localStorage.displayTitle = BICONOMY_EXPERIENCE;
    }
    window.location.reload();
  }

  render() {
    return (
      <StyleRoot>
        <div className="header">
          <div className="logo" style={styles.rubberBand}>
            book<span className="logo-second-half">Reads</span>
          </div>
          <div className="user-info">

            <div className="select-network-container mb2">
              <FormSelect className="network-select" onChange={this.onNetworkChanged} value={this.props.selectedNetworkId}>
                <option value="80001">Matic Testnet (Mumbai)</option>
                <option value="42">Kovan</option>
                {/* <option value="31">RSK Testnet</option> */}
              </FormSelect>
            </div>

            <div className="user-balance-container">
              {this.state.switchButtonTitle && this.props.loggedIn && (
              <Button onClick={this.onSwitchExperience} className="switchDAppsBtn">
                {this.state.switchButtonTitle}
              </Button>)}

              {this.props.loggedIn && (
                <div className="user-balance pt-2 pb-2 pl-2 pr-2">
                  {this.props.userBalance > -1 && (
                    <span>User Balance: {this.props.userBalance} ETH</span>
                  )}
                  {this.props.userBalance == -1 && (
                    <span>{"Fetching User Balance ..."}</span>
                  )}
                </div>
              )}

              {!this.props.loggedIn && (
                <Button
                  theme="success"
                  className="get-started pt-2 pb-2 pl-2 pr-2"
                  onClick={this.props.getStarted}
                >
                  {this.state.getStartedTitle}
                </Button>
              )}
              {this.props.loggedIn && (
                <Button
                  theme="danger"
                  className="logout-btn pt-2 pb-2 pl-2 pr-2"
                  onClick={this.logout}
                >
                  <i className="material-icons logout-icon">exit_to_app</i>{" "}
                  {this.state.logoutTitle}
                </Button>
              )}
            </div>
          </div>

          <div className="clear" />
        </div>
      </StyleRoot>
    );
  }
}

export default Header;
