import React from 'react';
import Header from './components/Header.js';
import MyFavList from './components/MyFavList.js';
import BookTile from './components/BookTile.js';
import LoginModal from './components/LoginModal.js';
import LoadingOverlay from 'react-loading-overlay';
import Cookies from 'js-cookie'
import {toBuffer} from "ethereumjs-util";
import abi from "ethereumjs-abi";
import events from "events";

import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';
import "./styles/shards-dashboards.1.0.0.min.css";
import jwt_decode from "jwt-decode";
import web3Obj from './components/helper';
import {dispatchEvent,dispatchErrorEvent, USER_SIGNATURE_PROVIDED_ON_BOOK_RATE, LOGOUT,BICONOMY_LOGIN,
USER_SIGNATURE_PROVIDED_ON_ADD_TO_FAV} from './util';
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import Biconomy from "@biconomy/mexa";
import Web3 from 'web3';
import { bounce, rubberBand, pulse, fadeIn } from 'react-animations';
import Radium, {StyleRoot} from 'radium';
import Congratulations from './components/Congratulation.js';

const styles = {
  bounce: {
    animation: 'x 1s',
    animationName: Radium.keyframes(bounce, 'bounce')
  },
  rubberBand: {
    animation: 'x 1s',
    animationName: Radium.keyframes(rubberBand, 'rubberBand')
  },
  pulse: {
    animation: 'x 1s',
    animationName: Radium.keyframes(pulse, 'pulse')
  },
  fadeIn: {
    animation: 'x 2s',
    animationName: Radium.keyframes(fadeIn, 'fadeIn')
  }
}
const {getPortisInstance} = require('./util');
const { config, constants } = require("./config");
const DEMO_WITH_META_TX = "DEMO_WITH_META_TX";

let data = require('./books.json');
let biconomy;
let web3;
const DEFAULT_OVERLAY_TEXT = "Loading your content ...";

const domainType = [
  { name: "name", type: "string" },
  { name: "version", type: "string" },
  { name: "chainId", type: "uint256" },
  { name: "verifyingContract", type: "address" }
];

const metaTransactionType = [
  { name: "nonce", type: "uint256" },
  { name: "from", type: "address" },
  { name: "functionSignature", type: "bytes" }
];

let domainData = {
  name: "BookReads",
  version: "1"
};

class App extends React.Component {

	constructor(props) {
		super(props);
		this.state= {
			favList: ['The Harry Potter','Monster Games','The Rally Go'],
			open: false,
			loggedIn: false,
			portisLoggedIn: false,
      metamaskLoggedIn: false,
			userContract: "",
      userAddress: "",
      userBalance: -1,
      userTorusAddress : '',
      isTransactionSuccessfull: false,
      transactionLink: '',
      transactionHash: '',
      isOverlayVisible: false,
      overlayText: DEFAULT_OVERLAY_TEXT,
      selectedNetworkId:80001,
      showCongratsModal: false
		};
		this.onGetStarted = this.onGetStarted.bind(this);
		this.toggleLogin = this.toggleLogin.bind(this);
		this.setLogin = this.setLogin.bind(this);
    this._setLogin = this._setLogin.bind(this);
		this.initDapp = this.initDapp.bind(this);
		this.checkLogin = this.checkLogin.bind(this);
		this.onRateBook = this.onRateBook.bind(this);
		this.getBookAvgRating = this.getBookAvgRating.bind(this);
		//this.getReadAPIData = this.getReadAPIData.bind(this);
		this.getUserRating = this.getUserRating.bind(this);
		this.getUserFavStatus = this.getUserFavStatus.bind(this);
		this.logout = this.logout.bind(this);
		this.onAddToFav = this.onAddToFav.bind(this);
		this.getUserBookList = this.getUserBookList.bind(this);
		this.closeTransactionHash = this.closeTransactionHash.bind(this);
		//this.getBiconomyWalletSignature = this.getBiconomyWalletSignature.bind(this);
    this.portisLogin = this.portisLogin.bind(this);
    this.matchLoginAddress = this.matchLoginAddress.bind(this);
    this.onAddressChanged = this.onAddressChanged.bind(this);
    this.onNetworkChanged = this.onNetworkChanged.bind(this);
    this.checkSelectedNetwork = this.checkSelectedNetwork.bind(this);
    this.showOverlay = this.showOverlay.bind(this);
    this.hideOverlay = this.hideOverlay.bind(this);
    this.changeOverlayText = this.changeOverlayText.bind(this);
    this.getExplorerLink = this.getExplorerLink.bind(this);
    this.checkFirstTransaction = this.checkFirstTransaction.bind(this);
    this.toggleCongratsModal = this.toggleCongratsModal.bind(this);
    this.executeMetaTransaciton = this.executeMetaTransaciton.bind(this);
    this.constructMetaTransactionMessage = this.constructMetaTransactionMessage.bind(this);
    this.getSignatureParameters = this.getSignatureParameters.bind(this);
  }

  async executeMetaTransaciton(userAddress, functionSignature, contract, contractAddress, chainId) {
    var eventEmitter = new events.EventEmitter();
    if(contract && userAddress && functionSignature, chainId, contractAddress) {
      let nonce = await contract.methods.getNonce(userAddress).call();
      let dataToSign = this.constructMetaTransactionMessage(nonce, functionSignature, userAddress);
      let self = this;
      web3.currentProvider.send(
        {
          jsonrpc: "2.0",
          id: 999999999999,
          method: "eth_signTypedData_v3",
          params: [userAddress, dataToSign]
        },
        function(error, response) {
          console.info(`User signature is ${response.result}`);
          if (error || (response && response.error)) {
            NotificationManager.error("Could not get user signature");
          } else if (response && response.result) {
            let { r, s, v } = self.getSignatureParameters(response.result);

            console.log("before transaction listener");
            // No need to calculate gas limit or gas price here
            let transactionListener = contract.methods.executeMetaTransaction(userAddress, functionSignature, r, s, v).send({
                from: userAddress
            });

            transactionListener.on("transactionHash", (hash)=>{
              eventEmitter.emit("transactionHash", hash);
            }).once("confirmation", (confirmation, recipet) => {
              eventEmitter.emit("confirmation", confirmation, recipet);
            }).on("error", error => {
              eventEmitter.emit("error", error);
            });
          }
        }
      );

      return eventEmitter;
    } else {
      console.log("All params userAddress, functionSignature, chainId, contract address and contract object are mandatory");
    }
  }

  constructMetaTransactionMessage(nonce, functionSignature, userAddress) {
    let message = {};
    message.nonce = parseInt(nonce);
    message.from = userAddress;
    message.functionSignature = functionSignature;

    const dataToSign = JSON.stringify({
      types: {
        EIP712Domain: domainType,
        MetaTransaction: metaTransactionType
      },
      domain: domainData,
      primaryType: "MetaTransaction",
      message: message
    });
    return dataToSign;
  }

  getSignatureParameters(signature) {
    if (!web3.utils.isHexStrict(signature)) {
      throw new Error(
        'Given value "'.concat(signature, '" is not a valid hex string.')
      );
    }
    var r = signature.slice(0, 66);
    var s = "0x".concat(signature.slice(66, 130));
    var v = "0x".concat(signature.slice(130, 132));
    v = web3.utils.hexToNumber(v);
    if (![27, 28].includes(v)) v += 27;
    return {
      r: r,
      s: s,
      v: v
    };
  };

  checkFirstTransaction() {
    let oldUser = Cookies.get('oldUser');
    if(!oldUser) {
      Cookies.set('oldUser', 'true');
      this.setState({showCongratsModal: true});
    }
  }

  toggleCongratsModal() {
    this.setState({showCongratsModal: !this.state.showCongratsModal});
  }

  onNetworkChanged(event) {
    let selectedNetworkId = event.target.value;
    switch(selectedNetworkId) {
      case "42":
      case "80001":
        localStorage.setItem(config.selectedNetworkKey, selectedNetworkId);
        this.setState({selectedNetworkId : selectedNetworkId, isOverlayVisible: true});
        window.location.reload();
        break;
      default:
        NotificationManager.error("Current Network is not supported", "Error", 4000);
        break;
    }
  }

	closeTransactionHash() {
		this.setState({isTransactionSuccessfull: false});
	}

	logout() {
    let biconomyMode = localStorage.displayPage;
    localStorage.clear();
    if(biconomyMode) {
      localStorage.displayPage = biconomyMode;
    }
    if(web3Obj && web3Obj.logout) {
      console.log('torus logout')
      web3Obj.logout();
    }
		this.setState({
		  loggedIn: false,
		  torusLoggedIn: false,
			isTransactionSuccessfull: false,
			biconomyWalletLoggedIn: false
		});
    dispatchEvent(LOGOUT);
    localStorage.setItem(constants.version, config.version);
	}

	onGetStarted() {
		this.setState({open: true});
	}

  portisLogin() {

  }

	toggleLogin() {
		this.setState({
		  open: !this.state.open
		});
	}

	setLogin(biconomy, result, wallet) {
    let self = this;
    this._setLogin(biconomy, wallet);
	}

  _setLogin(biconomy, wallet) {
    this.setState({
      loggedIn: true
    });

    let portisAddress = localStorage.getItem("portisAddress");
    let metamaskAddress = localStorage.getItem("metamaskAddress");
    if(portisAddress) {
      this.setState({portisLoggedIn: true});
    } else if(metamaskAddress) {
      this.setState({metamaskLoggedIn: true});
    }
    this.initDapp(biconomy, wallet);
  }

	async checkLogin() {

    let portisAddress = localStorage.getItem("portisAddress");
    let metamaskAddress = localStorage.getItem("metamaskAddress");

		if(portisAddress) {
      this.setState({loggedIn: true, portisLoggedIn: true, userAddress: portisAddress});
    } else if(metamaskAddress) {
      this.setState({loggedIn: true, metamaskLoggedIn: true, userAddress: metamaskAddress});
    }
		this.initDapp();
	}


	async initDapp(bic, wallet) {
    let self = this;
    const portisAddress = localStorage.getItem("portisAddress");
    const metamaskAddress = localStorage.getItem("metamaskAddress");

    try{
      let userAddress = undefined;
      if(portisAddress) {
        userAddress = portisAddress;
        console.log('Portis loggedin .. ');
        if(localStorage.displayPage == DEMO_WITH_META_TX) {
          console.log(this.state.selectedNetworkId);
          if(!bic) {
            console.log("not biconomy")
            self.changeOverlayText("Initializing portis wallet ...");
            let portis = getPortisInstance(this.state.selectedNetworkId);
            portis.onActiveWalletChanged(walletAddress => {
              this.onAddressChanged(self.state.userAddress, walletAddress);
            });
            biconomy = new Biconomy(portis.provider, {
                apiKey: config.networkConfig[this.state.selectedNetworkId].bookReadAPIKey,
                debug: true
            });
          } else {
            biconomy = bic;
            if(wallet) {
              wallet.onActiveWalletChanged(walletAddress => {
                console.log(`Address changed`)
                this.onAddressChanged(self.state.userAddress, walletAddress);
              });
            }
          }
          biconomy.onEvent(biconomy.READY, ()=> {
            self.hideOverlay();
            dispatchEvent(BICONOMY_LOGIN, "");
          }).on(biconomy.ERROR, (error, message)=> {
            self.hideOverlay();
            if(message && message.message) {
              NotificationManager.error(`${message.message}. Error while initializing app. Try selecting different network`, "Error", 4000);
              console.error(message);
            } else if(error && error.message) {
              console.error(error);
              NotificationManager.error(`${error.message}. Error while initializing app. Try selecting different network`, "Error", 4000);
            } else {
              console.error(message);
              NotificationManager.error(error, "Error", 5000);
            }

          });
          web3 = new Web3(biconomy);
        } else {
          console.log(this.state.selectedNetworkId);
          self.changeOverlayText("Initializing portis wallet ...");
          let portis = getPortisInstance(this.state.selectedNetworkId);
          if(!portis) {
            NotificationManager.error("Portis doesn't support selected network", "Error", 3000);
          }
          web3 = new Web3(portis.provider);
        }

        web3.eth.defaultAccount = userAddress;

        self.changeOverlayText("Getting your account balance ...");
        web3.eth.getBalance(userAddress).then((balance) => {
          if(localStorage.displayPage != DEMO_WITH_META_TX) {
            self.hideOverlay();
          } else {
            self.changeOverlayText("Initializing Biconomy ...");
          }
          console.log(`balace form web3 ${balance}`)
          let balanceInEth = web3.utils.fromWei(balance, 'ether');
          self.setState({
              userBalance: balanceInEth,
              portisLoggedIn: true,
              userAddress: portisAddress
            });
          console.log('initializing bookreads contract');
          self.bookReads = new web3.eth.Contract(config.networkConfig[this.state.selectedNetworkId].bookReadsABI,
            config.networkConfig[this.state.selectedNetworkId].bookReadsAddress);
          domainData.verifyingContract = config.networkConfig[this.state.selectedNetworkId].bookReadsAddress;
          domainData.chainId = parseInt(this.state.selectedNetworkId);
          dispatchEvent(BICONOMY_LOGIN, "");
        });
      } else if(metamaskAddress) {
        userAddress = metamaskAddress;
        console.log('Metamask loggedin .. ');
        if(localStorage.displayPage == DEMO_WITH_META_TX) {
          if(!bic) {
            self.changeOverlayText("Initializing Biconomy ...");
            biconomy = new Biconomy(window.ethereum, {
              apiKey: config.networkConfig[this.state.selectedNetworkId].bookReadAPIKey,
              debug: true
            });
          } else {
            biconomy = bic;
          }
          web3 = new Web3(biconomy);

          biconomy.onEvent(biconomy.READY, ()=> {
            self.hideOverlay();
          }).on(biconomy.ERROR, (error, message)=> {
            self.hideOverlay();
            let showMessage;
            if(error && error.message && error.code == 'B505') {
              NotificationManager.error('Selected network in metamask is different from network selected in app','Error',5000);
            } else if(message && message.message) {
              NotificationManager.error(`Error while initializing app. ${message.message} Try selecting different network`, "Error", 4000);
            } else {
              NotificationManager.error('Error while initializing the app', 'Error', 3000);
            }
            console.error(message);
          });
        } else {
          web3 = new Web3(window.ethereum);
        }

        window.ethereum.on('accountsChanged', function (accounts) {
          console.log(accounts);
          self.onAddressChanged(self.state.userAddress, accounts[0]);
        })

        let addressMatched = await self.matchLoginAddress(userAddress, web3);

        if(!addressMatched) return;

        web3.eth.defaultAccount = userAddress;
        web3.eth.getBalance(userAddress).then((balance) => {
          console.log(`balace form web3 ${balance}`)
          let balanceInEth = web3.utils.fromWei(balance, 'ether');
          self.setState({
              userBalance: balanceInEth,
              metamaskLoggedIn: true,
              userAddress: metamaskAddress
            });
          console.log('initializing bookreads contract');
          self.bookReads = new web3.eth.Contract(config.networkConfig[this.state.selectedNetworkId].bookReadsABI,
            config.networkConfig[this.state.selectedNetworkId].bookReadsAddress);
          domainData.verifyingContract = config.networkConfig[this.state.selectedNetworkId].bookReadsAddress;
          domainData.chainId = parseInt(this.state.selectedNetworkId);
          dispatchEvent(BICONOMY_LOGIN, "");
          self.hideOverlay();
        });
      } else {
        self.hideOverlay();
      }
    } catch(error) {
      self.hideOverlay();
      NotificationManager.error("Error while initialize the app", "Error", 3000);
    }

  }

  async matchLoginAddress(oldAddress, web3) {
    if(web3 && oldAddress) {
      let accounts = await web3.eth.getAccounts();
      if(accounts && accounts[0]) {
        return await this.onAddressChanged(oldAddress, accounts[0]);
      }
      return true;
    }
  }

  async onAddressChanged(oldAddress, newAddress) {
    if(oldAddress && newAddress && oldAddress.toUpperCase() != newAddress.toUpperCase()) {
      if(this.state.loggedIn) {
        NotificationManager.warning("You have been logged out as your account has been changed in your wallet. Please login with new account.","Warning",10000);
      }
      this.logout();
      return false;
    }
    return true;
  }

  async onRateBook(bookId, rating, callback) {

  	let self = this;
    if(this.state.userAddress && web3) {
      let addressMatched = await self.matchLoginAddress(this.state.userAddress, web3);
      if(!addressMatched) return;
    }

  	if(this.bookReads) {
      let functionSignature = this.bookReads.methods.addRating(bookId, rating).encodeABI();

      let result = await this.executeMetaTransaciton(this.state.userAddress, functionSignature, this.bookReads, config.networkConfig[this.state.selectedNetworkId].bookReadsAddress, this.state.selectedNetworkId);

  		result.on("transactionHash", () => {
        NotificationManager.success(
            "Transaction sent. Waiting for confirmation ... ",
            "Message",
            3000
          );
        dispatchEvent(USER_SIGNATURE_PROVIDED_ON_BOOK_RATE, {bookId: bookId});
      }).once("confirmation", (confirmationCount, receipt) => {
        NotificationManager.success(
              "Book rating successfull",
              "Success",
              3000
            );
        const transactionLink = self.getExplorerLink(receipt.transactionHash);
        setTimeout(
              () => {
                self.checkFirstTransaction();
                self.setState({
                  isTransactionSuccessfull: true,
                  transactionLink: transactionLink,
                  transactionHash: receipt.transactionHash
                })
              },
              1000
            );
        callback();
      }).on("error", (error)=>{
        if(error.code == "B503") {
          NotificationManager.error(
            "Please logout and login again.",
            "Error",
            3000
          );
        } else if(error.code && error.message) {
          NotificationManager.error(
            `${error.message}`,
            "Error",
            3000
          );
        } else {
          NotificationManager.error(
            "Could not rate book.",
            "Error",
            3000
          );
        }

        console.log(error);
        callback(error);
        dispatchErrorEvent();
      });
    }
  }



	async onAddToFav(bookId, callback) {
  	let self = this;
    if(this.state.userAddress && web3) {
      let addressMatched = await self.matchLoginAddress(this.state.userAddress, web3);
      if(!addressMatched) return;
    }
    if(this.bookReads) {

      let functionSignature = this.bookReads.methods.addToFav(bookId).encodeABI();

      let result = await this.executeMetaTransaciton(this.state.userAddress, functionSignature, this.bookReads, config.networkConfig[this.state.selectedNetworkId].bookReadsAddress, this.state.selectedNetworkId);

      result.on("transactionHash", () => {
        NotificationManager.success(
            "Transaction sent. Waiting for confirmation ... ",
            "Message",
            3000
          );
        dispatchEvent(USER_SIGNATURE_PROVIDED_ON_ADD_TO_FAV, {bookId: bookId});
      }).once("confirmation", (confirmationCount, receipt) => {
        NotificationManager.success(
              "Book added to List",
              "Success",
              3000
            );
        const transactionLink = self.getExplorerLink(receipt.transactionHash);

        setTimeout(
              () => {
                self.checkFirstTransaction();
                self.setState({
                  isTransactionSuccessfull: true,
                  transactionLink: transactionLink,
                  transactionHash: receipt.transactionHash
                })
              },
              1000
            );
        callback();
      }).on("error", (error)=>{
        NotificationManager.error(
              "Could not add book to fav list.",
              "Error",
              3000
            );
        console.log(error);
        callback(error);
        dispatchErrorEvent();
      });
    }
  }

  async getBookAvgRating(bookId) {
    let response;
    if(this.bookReads) {
      let {totalCount, totalRating} = await this.bookReads.methods.getAvgBookRating(bookId).call();
      response = {};
      response.totalCount = totalCount;
      response.totalRating = totalRating;
    }
    return response;
  }

  async getUserRating(bookId) {
    let response;
    if(this.bookReads) {
      let userRating = await this.bookReads.methods.getUserRating(bookId, this.state.userAddress).call();
      response = userRating;
    }
    return response;
  }

  async getUserFavStatus(bookId) {
    let response;
    if(this.bookReads) {
      let userFavStatus = await this.bookReads.methods.getBookFavStatus(bookId, this.state.userAddress).call();
      response = userFavStatus;
    }
    return response;
  }

  async getUserBookList() {
  	let response;
    if(this.bookReads) {
      let userBookList = await this.bookReads.methods.getBookList(this.state.userAddress).call();
      response = userBookList;
    }
    return response;
  }

  async checkSelectedNetwork() {
    let networkId = localStorage.getItem(config.selectedNetworkKey);
    if(!networkId) {
      networkId = "80001";
    }

    this.setState({selectedNetworkId : networkId});
  }

  showOverlay(text) {
    if(text) {
      this.setState({overlayText: text, isOverlayVisible: true});
    } else {
      this.setState({isOverlayVisible: true});
    }
  }

  changeOverlayText(text) {
    this.setState({overlayText: text});
  }

  hideOverlay() {
    this.setState({overlayText: DEFAULT_OVERLAY_TEXT, isOverlayVisible: false});
  }

  getExplorerLink(txHash) {
    switch(this.state.selectedNetworkId) {
      case "80001":
        return `https://mumbai-explorer.matic.today/tx/${txHash}`;
      case "42":
        return `https://kovan.etherscan.io/tx/${txHash}`;
      default:
        return "Explorer link not available for current network";
    }
  }

  async componentDidMount() {
    let localVersion = localStorage.getItem(constants.version);
    if(!localVersion || localVersion<config.version) {
      this.logout();
    }

    this.setState({overlayText: DEFAULT_OVERLAY_TEXT});
    this.showOverlay("Initializing Application ...")
    await this.checkSelectedNetwork();
    this.checkLogin();
  }



	render() {
		return (
			<StyleRoot>
			<div className="app">
        <Congratulations open={this.state.showCongratsModal} toggle={this.toggleCongratsModal} />
				<LoginModal open={this.state.open} toggleLogin={this.toggleLogin}
				setLogin={this.setLogin} initDapp={this.initDapp} portisLogin={this.portisLogin}
        selectedNetworkId={this.state.selectedNetworkId}/>
				<Header getStarted={this.onGetStarted} loggedIn={this.state.loggedIn} logout={this.logout}
				userBalance={this.state.userBalance} torusLogin={this.state.torusLoggedIn} onNetworkChanged={this.onNetworkChanged}
        selectedNetworkId={this.state.selectedNetworkId}></Header>

				<div className="main-content">
          {this.state.isTransactionSuccessfull &&
            <div className="transaction-container" style={styles.pulse}>
              <span className="transaction-label">Check your transaction here </span>
              <span className="transaction-hash"><a  href={this.state.transactionLink} target="_blank">{this.state.transactionHash}</a></span>
              <span className="transaction-hash-close-icon"><i className="material-icons" onClick={this.closeTransactionHash}>close</i></span>
            </div>
          }
          <div id="main-body">
            <div className="my-list-container" >
              <MyFavList list={this.state.favList} loggedIn={this.state.loggedIn} getUserBookList={this.getUserBookList}/>
            </div>
            <div className="main-content-container" style={styles.fadeIn}>
              <div className="main-title">Rate your favourite books on blockchain without crypto currency</div>
              <div className="book-list-container">
                {data.Books.map((data,index) => {
                  return <BookTile data={data} key={index} onRateBook={this.onRateBook} getAvgBookRating={this.getBookAvgRating}
                  getUserRating={this.getUserRating} loggedIn={this.state.loggedIn} onAddToFav={this.onAddToFav}
                  getUserFavStatus={this.getUserFavStatus} torusLoggedIn={this.state.torusLoggedIn} portisLoggedIn= {this.state.portisLoggedIn}
                  biconomyWalletLoggedIn={this.state.biconomyWalletLoggedIn} metamaskLoggedIn={this.state.metamaskLoggedIn}></BookTile>
                })}
              </div>
            </div>
					</div>
				</div>
				<NotificationContainer />
			</div>
      <LoadingOverlay
        active={this.state.isOverlayVisible}
        spinner
        text={this.state.overlayText}>
      </LoadingOverlay>
			</StyleRoot>
		);
	}
}

export default App;
